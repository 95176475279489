import React from 'react';
import { Flex, Text } from '@ellipsedata/tennis';

const EnvironmentIndicator = (props) => {
  return (
    <Flex justifyContent="center" alignItems="center" backgroundColor="blue.800" p={2}>
      <Text color="white" fontWeight="bold">
        {props.envName && props.envName.toUpperCase()}
      </Text>
    </Flex>
  );
};

export default EnvironmentIndicator;
